<template>
    <div class="header-main">
        <div id="div0">
            <div id="return" @click="$router.back()" v-if="topTypeState === 1">
                <img :src="imgUrl + 'return-img.png'" alt="">
            </div>
            <div style="width: 4.4rem" v-if="topTypeState === 1"></div>
            <div id="header-title" v-if="topTypeState === 1">
                <Button1 :attr='attr'></Button1>
            </div>


            <!--头部头像-->
            <div id="avatar" v-if="topTypeState === 2">
                <img :src="avatarImg" alt="">
            </div>
            <div id="name" v-if="topTypeState === 2">{{ nickname }}</div>

            <Button1 :attr='groupAttr' :audio="true" v-if="topTypeState === 2"></Button1>

            <div style="flex: 1 1 auto;" />

            <div id="refresh-page" @click="handleRefreshPage" class="action-button">
                <svg-icon icon-class="refresh-icon" class="fullscreen-icon-class"></svg-icon>
            </div>

            <div id="fullscreen" @click="handleFullScreen" class="action-button">
                <svg-icon icon-class="fullscreen-icon" class="fullscreen-icon-class"></svg-icon>
            </div>

            <!--设置-->
            <div id="set">
                <div @click="handleMenu">
                    <img :src="imgUrl + 'set-icon.png'" alt="">
                </div>
                <SelectMenu v-if="menuState" :visible.sync='menuState'></SelectMenu>
            </div>
        </div>

        <SelectGroup v-if="selectGroupState" :visible.sync='selectGroupState' :list="homeClassData"
            @handleConfirmGroup="handleConfirmGroup"></SelectGroup>
    </div>
</template>

<script>
import SelectMenu from "@/components/ipad/SelectMenu";
import Button1 from "@/components/ipad/Button-1";
import { full } from "@/views/ipad/common";
import SelectGroup from "@/views/ipad/Home/components/SelectGroup";
import TeacherUser from "@/models/Home/TeacherUser";
import websocket from "@/utils/websocket";
import eventVue from "@/assets/js/event";
import UnloadClass from "@/models/Home/UnloadClass";

export default {
    name: "IpadHeader",
    components: {
        SelectMenu,
        Button1,
        SelectGroup
    },
    data() {
        return {
            avatarImg: 'https://storage.shubenji.cn/GuoBao/ipad/ip3.png',
            nickname: '果宝',
            menuState: false,
            imgUrl: 'https://storage.shubenji.cn/GuoBao/ipad/',
            attr: {
                name: this.$route.meta.title,
                // click: { click: _ => this.handleUpdateInfo() }
            },
            groupAttr: {
                name: '选择班级',
                click: { click: _ => this.handleSelectCourse() }
            },
            topTypeState: 1,
            selectGroupState: false,
            homeClassData: [],
            homeCourseData: [],
            homeBasisData: {},
        }
    },
    created() {
        if (this.$route.path === '/ipad/home') {
            this.homeBasisInfo();
            this.topTypeState = 2
        }

        let _this = this;
        eventVue.$on('onAttrCourse', function (message) {
            _this.attr.name = message.title
        })

        //缓存区分班级课程类型
        if (this.$store.getters.getUserData.role === 2) {
            _this.listUnlockedLessonNumInfo();
            _this.canUnlockState();
            setInterval(function () {
                _this.canUnlockState();
                _this.listUnlockedLessonNumInfo();
            }, 300000) //60000 改成了5分钟
        }
    },
    mounted() {

    },
    watch: {
        $route(newVal, oldVal) {
            console.log(newVal, oldVal)
            if (newVal.path === '/ipad/home') {
                this.homeBasisInfo();
                this.topTypeState = 2
            } else {
                this.attr.name = newVal.meta.title
                this.topTypeState = 1
            }
        }
    },
    methods: {
        //设置时间
        canUnlockState() {
            UnloadClass.canUnlock({}, ({ type, list }) => {
                if (type === 200) {
                    if (list !== 0) {
                        this.$store.commit('setCanUnlock', list)
                    } else {
                        this.$store.commit('setCanUnlock', list)
                    }
                }
            })
        },
        handleMenu() {
            this.menuState = true;
        },
        handleRefreshPage() {
            window.location.reload()
        },
        handleFullScreen() {
            full.Fullscreen("#app");
        },
        handleSelectCourse() {
            this.selectGroupState = true;
        },
        //首页基本信息
        homeBasisInfo() {
            TeacherUser.getBasisInfo({}, ({ type, list }) => {
                if (type === 200) {
                    this.homeBasisData = list;
                    this.homeGroupList();
                    this.nickname = list.userinfo.nickname;
                    if (list.userinfo.user_avatar !== "") {
                        this.avatarImg = list.userinfo.user_avatar;
                    }

                    localStorage.setItem("goodsCategory", list.bureau.goods_category);
                }
            })
        },
        //班级列表
        homeGroupList() {
            const params = {
                limit: 999999,
                page: 1
            }
            TeacherUser.getGroupList(params, ({ type, list }) => {
                if (type === 200) {
                    if (this.$store.getters.getGroupData === null) {
                        if (list.list.length !== 0) {
                            this.$store.commit('setGroupData', {
                                group_id: list.list[0].group_id,
                                group_name: list.list[0].group_name
                            });
                        }
                    }
                    this.homeClassData = list.list;
                    this.groupAttr.name = this.$store.getters.getGroupData.group_name;
                    this.homeCourseList();
                }
            })
        },
        //首页课程标题
        homeCourseList() {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({ type, list }) => {
                if (type === 200) {
                    this.homeCourseData = list.list;
                    this.$store.commit('setCourseData', list.list);
                    this.state1 = true;
                    this.state = true;
                    this.timer = new Date().getTime()
                    this.codeNum = new Date().getTime() + 1;

                    websocket.Send({
                        // token: JSON.parse(localStorage.getItem('info')).token,
                        token: this.$store.getters.getUserData.token,
                        conn_id: this.theConnId,
                        data: '',
                        group_id: this.$store.getters.getGroupData.group_id,
                        action: 'group_state'
                    });
                }
            })
        },
        //切换班级
        handleConfirmGroup(val) {
            if (val === true) {
                this.groupAttr.name = this.$store.getters.getGroupData.group_name;
            }
        },
        //添加缓存 只有学生端的时候
        async listUnlockedLessonNumInfo() {
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
                if (type === 200) {
                    _this.$store.commit('setUnloadGroupData', list.list.lesson_num)
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.header-main {
    height: 8rem;

    #div0 {
        height: 8rem;
        margin: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #return {
            width: 4.4rem;
            height: 4.4rem;

            img {
                width: 100%;
            }
        }

        #avatar {
            width: 5.4rem;
            height: 5.4rem;

            img {
                width: 4.2rem;
                height: 4.2rem;
                border-radius: 100%;
                border: 0.4rem solid #FFFFFF;
                background: #c4c4c4;
            }
        }

        #name {
            font-size: 1.8rem;
            color: #FFFFFF;
            font-weight: bold;
            margin: 0 1.6rem;
        }


        #header-title {
            align-items: center;
            margin-left: auto;
        }

        .action-button {
            width: 4rem;
            height: 4rem;
            margin-left: 2rem;

            .fullscreen-icon-class {
                width: 4rem;
                height: 4rem;
                color: #FFFFFF;
            }
        }

        #set {
            width: 4.4rem;
            height: 4.4rem;
            border: 0.1rem solid #DCEBFF;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 2rem;
            position: relative;

            img {
                width: 3.2rem;
                height: 3.2rem;
            }

            .setting-menu {
                position: relative;

            }
        }
    }
}
</style>
