<template>
    <div class="home-content">
        <div id="center">
            <div id="div1">
                <div class="left-img">
                    <img :src="imgUrl + 'home-ip1.png'" alt="">
                </div>
                <div class="right-list">
                    <div class="item one">
                        <div @click="handleCourses">
                            <img class="bkg" :src="imgUrl + 'course-bkg3.png'" alt="">
                            <img class="ip ip1" :src="imgUrl + 'ip1.png'" alt="">
                        </div>
                        <div @click="handleWorks">
                            <img class="bkg" :src="imgUrl + 'work-bkg3.png'" alt="">
                            <img class="ip ip1" :src="imgUrl + 'ip3.png'" alt="">
                        </div>
                    </div>
                    <div class="item two">
                        <div @click="handleCreativeTools">
                            <img class="bkg" :src="imgUrl + 'ctool-bkg3.png'" alt="">
                            <img class="ip ip1" :src="imgUrl + 'ip5.png'" alt="">
                        </div>
                        <div @click="handleGames">
                            <img class="bkg" :src="imgUrl + 'tool-bkg3.png'" alt="">
                            <img class="ip ip4" :src="imgUrl + 'ip4.png'" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SelectGroup v-if="selectGroupState" :visible.sync='selectGroupState' :list="homeClassData"
            @handleConfirmGroup="handleConfirmGroup"></SelectGroup>

        <audio id="audio">
            <source src="../../../assets/pop.mp3" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
import { createdLoad, full } from "@/views/ipad/common";
import SelectGroup from "@/views/ipad/Home/components/SelectGroup";
import Button1 from "@/components/ipad/Button-1";
import SelectMenu from "@/components/ipad/SelectMenu";
import TeacherUser from "@/models/Home/TeacherUser";
import websocket from "@/utils/websocket";
import eventVue from "@/assets/js/event";

export default {
    name: "index",
    components: {
        SelectGroup,
        Button1,
        SelectMenu,
    },
    data() {
        return {
            imgUrl: 'https://storage.shubenji.cn/GuoBao/ipad/',
            nickname: '果宝',
            avatarImg: 'https://storage.shubenji.cn/GuoBao/ipad/ip3.png',
            selectGroupState: false,
            attr: {
                name: '选择班级',
                click: { click: _ => this.handleSelectCourse() }
            },
            menuState: false,
            homeClassData: [],
            homeCourseData: [],
            homeBasisData: {},
        }
    },
    created() {
        createdLoad();

        const self = this;
        eventVue.$on('onBasicInfo', function (message) {
            self.nickname = message.nickname;
            self.avatarImg = message.avatarImg;
            console.log(self.nickname)
            let newStore = {
                nickname: message.nickname,
                avatar: message.avatarImg,
                gender: self.$store.getters.getUserData.gender,
                role: self.$store.getters.getUserData.role,
                token: self.$store.getters.getUserData.token,
                username: self.$store.getters.getUserData.username,
            }
            // console.log(newStore)
            self.$store.commit('setUserData', newStore)
        })
    },
    watch: {},
    methods: {
        handleRefresh() {
            window.location.reload()
        },
        handleFullScreen() {
            full.Fullscreen(".home-content");
        },
        handleCourses() {
            this.$router.push({ path: '/ipad/course' })
        },
        handleWorks() {
            this.$router.push({ path: '/ipad/work' })
        },
        handleCreativeTools() {
            this.$router.push({ path: '/ipad/creative_tools' })
        },
        handleGames() {
            this.$router.push({ path: '/ipad/game' })
        },

        handleSelectCourse() {
            // var sound = document.getElementById("audio");
            // sound.play();

            this.selectGroupState = true;
        },
        handleMenu() {
            this.menuState = true;
        },
        //切换班级
        handleConfirmGroup(val) {
            if (val === true) {
                this.attr.name = this.$store.getters.getGroupData.group_name;
            }
        }
    }
}
</script>

<style lang="less" scoped>
* {
    -webkit-user-select: none;
    /*禁用手机浏览器的用户选择功能 */
    -moz-user-select: none;
}

img {
    //pointer-events: none;
}


.home-content {
    //width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    //background: #939355;
}

.home-content #center::-webkit-scrollbar {
    width: 0 !important
}

.home-content #center {
    -ms-overflow-style: none;
}

.home-content #center {
    overflow: -moz-scrollbars-none;
}

.home-content #center {
    //margin: 0 2.8rem;

    height: calc(100vh - 12rem);
    overflow-y: scroll;
    /*background: #ffeee1;*/
}

.home-content #center #div1 {

    display: flex;
}

.home-content #center #div1 .left-img {
    display: none;
}

.home-content #center #div1 .right-list {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
}

.home-content #center #div1 .right-list .one {
    /*flex:  0 0 100%;*/
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
}

.home-content #center #div1 .right-list .item {
    /*margin-top: 2%;*/
}

.home-content #center #div1 .right-list .one div {
    flex: 0 0 48%;
    width: 50%;
    border-radius: 2rem;
    border: 1px solid #FFFFFF;
    position: relative;
}

.home-content #center #div1 .right-list .one div .bkg {
    width: 100%;
}

.home-content #center #div1 .right-list .one div .ip {
    width: 30%;
    position: absolute;
    right: 0;
    bottom: 2%;
}

.home-content #center #div1 .right-list .two {
    /*flex:  0 0 100%;*/
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
}

.home-content #center #div1 .right-list .two div {
    flex: 0 0 48%;
    width: 50%;
    border-radius: 2rem;
    border: 0.1rem solid #FFFFFF;
    position: relative;
}

.home-content #center #div1 .right-list .two div .bkg {
    width: 100%;
}

.home-content #center #div1 .right-list .two div .ip {
    width: 30%;
    position: absolute;
    right: 0;
    bottom: 2rem;
}

@media screen and (min-width: 701px) and (orientation: landscape) {
    .home-content {
        //background-image: url("../../../assets/img/home-bkg2.png");
        //background-size: 100%;
        //background-repeat: no-repeat;
    }

    .home-content #center #div1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 2rem;
    }

    .home-content #center #div1 .left-img {
        display: inline-block;
        flex: 0 0 30%;

    }

    .home-content #center #div1 .left-img img {
        width: 100%;
    }

    .home-content #center #div1 .right-list {
        flex: 0 0 68%;
        display: flex;
        flex-direction: column;
    }

    .home-content #center #div1 .right-list .one {
        background-image: url("https://storage.shubenji.cn/GuoBao/ipad/core-bkg1.png");
        background-size: 100% 100%;
        align-items: flex-end;
    }

    .home-content #center #div1 .right-list .one div {
        border: none;
        padding: 3rem 3rem 6rem;
    }

    .home-content #center #div1 .right-list .one div .ip {
        margin-bottom: 15%;
        margin-right: 8%;
    }

    .home-content #center #div1 .right-list .one div:first-child {
        flex: 1 0 48%;
    }

    .home-content #center #div1 .right-list .one div:nth-child(2) {
        flex: 1 0 38%;
        margin-left: -4%;
    }

    .home-content #center #div1 .right-list .one div:nth-child(2) .bkg {}

    .home-content #center #div1 .right-list .two {
        justify-content: space-around;
        align-items: flex-end;
    }

    .home-content #center #div1 .right-list .two div {
        padding: 1rem;
    }

    .home-content #center #div1 .right-list .two div:nth-child(2) {
        flex: 0 0 38%;

    }

    .home-content #center #div1 .right-list .two div:nth-child(1) {
        flex: 0 0 44%;
    }

    .home-content #center #div1 .right-list .two div:nth-child(1) .ip {
        width: 40%;
        bottom: -10%;
        right: -5%;
    }

    .home-content #center #div1 .right-list .two div:nth-child(2) .ip {
        width: 60%;
        bottom: -8%;
        right: -8%;
    }
}

@media screen and (min-width: 601px) and (orientation: portrait) {
    .home-content {
        background-image: url("../../../assets/img/home-bkg1.png");
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .home-content #center #div1 {
        display: flex;
        flex-direction: column-reverse;
    }

    .home-content #center #div1 .left-img {
        display: block;
        width: 38%;
        margin: 2% 0;
    }

    .home-content #center #div1 .left-img img {
        width: 100%;
    }

    .home-content #center #div1 .right-list {
        display: flex;
        flex-direction: row;
        /*align-items: flex-start;*/
        justify-content: space-between;

    }

    .home-content #center #div1 .right-list .one {
        flex: 0 0 46%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: flex-start;*/
        /*background: #00dbb4;*/
        /*justify-content: center;*/
    }

    .home-content #center #div1 .right-list .one div {
        width: 100%;
        border: none;
        background-image: url("https://storage.shubenji.cn/GuoBao/ipad/core-bkg4.png");
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .home-content #center #div1 .right-list .one div .bkg {
        width: 80%;
        margin-top: -8%;
    }

    .home-content #center #div1 .right-list .one div:nth-child(1) {
        /*background: yellow;*/
    }

    .home-content #center #div1 .right-list .one div:nth-child(2) {
        /*margin-top: 6%;*/
    }

    .home-content #center #div1 .right-list .one div:nth-child(1) .ip {
        bottom: 21%;
        right: 10%;
    }

    .home-content #center #div1 .right-list .one div:nth-child(2) .ip {
        bottom: 21%;
        right: 10%;
    }

    .home-content #center #div1 .right-list .two {
        flex: 0 0 46%;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 15rem;
        align-items: flex-end;
        /*padding: 8rem 0;*/
    }

    .home-content #center #div1 .right-list .two div {
        width: 100%;
        border: none;
        background-image: url('https://storage.shubenji.cn/GuoBao/ipad/core-bkg4.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-content #center #div1 .right-list .two div .bkg {
        width: 80%;
        /*margin-left: 5%;*/
        margin: 4rem 0;
        position: relative;
        top: -6%;
    }

    .home-content #center #div1 .right-list .two div:nth-child(2) {
        margin-top: 10%;
    }

    .home-content #center #div1 .right-list .two div:nth-child(1) .ip {
        width: 40%;
    }

    .home-content #center #div1 .right-list .two div:nth-child(2) .ip {
        width: 50%;
    }
}
</style>
