<template>
    <div class="ipad-index">
        <div class="ipad-header">
            <Header></Header>
        </div>

        <div class="ipad-content">
            <router-view v-slot="{ Component }">
                <transition name="move" mode="out-in">
                    <keep-alive :include="tagsList">
                        <component :is="Component" />
                    </keep-alive>
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import Header from "@/components/ipad/Header";
import axios from "axios";

export default {
    name: "index",
    components: {
        Header
    },
    created() {
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
    },
    computed: {

        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
    }
}
</script>

<style lang="less" scoped>
.ipad-index {
    background-image: url("../../assets/img/home-bkg2.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    grid-template-rows: 8rem auto;
    flex-direction: column;
    height: 100%;
    .ipad-header{
        //background: yellow;
    }
    .ipad-content{
    }
}
</style>
